import { UserEditModalForm } from "./UserEditModalForm";

const UserEditModalFormWrapper = ({
  selectedListingId,
  setSelectedListingId,
  openCommissionModal,
  openEditListing,
  setOpenCommissionModal,
  setOpenEditListing,
  listing,
  getListing,
}) => {
  const itemIdForUpdate = null;
  const isLoading = false;

  if (selectedListingId) {
    return (
      <UserEditModalForm
        isUserLoading={isLoading}
        user={{ id: undefined }}
        setSelectedListingId={setSelectedListingId}
        selectedListingId={selectedListingId}
        openEditListing={openEditListing}
        setOpenEditListing={setOpenEditListing}
        openCommissionModal={openCommissionModal}
        setOpenCommissionModal={setOpenCommissionModal}
        listing={listing}
        getListing={getListing}
      />
    );
  }
  return null;
};

export { UserEditModalFormWrapper };
