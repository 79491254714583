import { KTIcon } from "../../../../_metronic/helpers";

const BlacklistHeader = ({ setOpenModal, type, setType, currentUser, haveAccess }) => {
  return (
    <div className="card-header border-0 pt-6 justify-content-end">
      <div style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
        <select
          className="form-select form-select-solid fw-bolder"
          value={type}
          onChange={e => setType(e.target.value)}
        >
          <option value="1">Emails</option>
          <option value="2">Phones</option>
        </select>
      </div>
    {(haveAccess(currentUser, 'blacklist_create')) &&
       <>
    <div className="card-toolbar" style={{ gap: "5px", marginRight: '12px' }}>
       <button
          type="button"
          className="btn"
          style={{ backgroundColor: "#2ecc71", color: "white" }}
          onClick={() => {
            setType('2')
            setOpenModal(true);
          }}
        >
          <KTIcon iconName="plus" className="fs-2 text-white" />
          Add Blacklist Phone
        </button>
      </div>
      <div className="card-toolbar" style={{ gap: "5px" }}>
        <button
          type="button"
          className="btn"
          style={{ backgroundColor: "#2ecc71", color: "white" }}
          onClick={() => {
            setType('1')
            setOpenModal(true);
          }}
        >
          <KTIcon iconName="plus" className="fs-2 text-white" />
          Add Blacklist Email
        </button>
      </div>
      </>
      }
    </div>
  );
};

export { BlacklistHeader };
