import { KTIcon } from "../../_metronic/helpers";
import moment from "moment";
import { useEffect, useState } from "react";
import './style.css'

const Reviews = ({ allReviews }) => {
  const [selectedReview, setSelectedReview] = useState("guest");
  const [reviews, setReviews] = useState([]);
  const [reviewsCount, setReviewsCount] = useState({
    guest: 0,
    host: 0
  });

  useEffect(() => {
    setReviewsCount({
      guest: allReviews?.filter((review) => +review.review_type === 2).length,
      host: allReviews?.filter((review) => +review.review_type === 1).length
    });
    if (selectedReview === "guest") {
      setReviews(allReviews?.filter((review) => +review.review_type === 2));
    } else if (selectedReview === "host") {
      setReviews(allReviews?.filter((review) => +review.review_type === 1));
    }
  }, [selectedReview, allReviews]);

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2>Reviews</h2>
      </div>
      <div
        style={{
          display: "flex",
          gap: "50px",
          marginTop: "5px"
        }}
      >
        <button
          style={{
            border: "none",
            background: "transparent",
            fontSize: "16px",
            borderBottom:
              selectedReview === "guest"
                ? "3px solid #ffa459"
                : "none",
            padding: "5px 2px",
            fontWeight: 600,
            color: "#4a4a4a"
          }}
          onClick={() => {
            setSelectedReview("guest");
          }}
        >
          Reviews from Trucker Member ({reviewsCount.guest})
        </button>
        <button
          style={{
            border: "none",
            background: "transparent",
            fontSize: "16px",

            borderBottom:
              selectedReview === "host"
                ? "3px solid #ffa459"
                : "none",
            padding: "5px 2px",
            fontWeight: 600,
            color: "#4a4a4a"
          }}
          onClick={() => {
            setSelectedReview("host");
          }}
        >
          Reviews from Property Member ({reviewsCount.host})
        </button>
      </div>
      <div className="row mt-10">
        {reviews && reviews.length > 0 ? (
          reviews.map((item) => (
            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center"
                }}
              >
                <div style={{ width: "80%" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "rgb(74, 74, 74)",
                          marginBottom: "8px"
                        }}
                      >
                        {item.review_type === 1 ? (
                          <>
                           <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                margin: "0 4px"
                              }}
                            >
                           Review for 
                           </span>
                          {item.customer_name}
                          <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                margin: "0 4px"
                              }}
                            >by
                            </span>
                            {item.owner_name}
                          </>
                        ) : (
                          <>
                            {item.customer_name}
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                margin: "0 4px"
                              }}
                            >
                              for
                            </span>
                            {item.listing_name}
                          </>
                        )}
                      </div>
                      <div style={{ display: "flex", gap: "5px" }}>
                        {[1, 2, 3, 4, 5].map((elem, index) => (
                          <KTIcon
                            key={index}
                            iconName="star"
                            className={
                              elem <= item.ratings
                                ? "active-rating"
                                : "inactive-rating"
                            }
                          />
                        ))}
                      </div>
                    </div>
                    <span
                      style={{
                        color: "#4a4a4a",
                        fontWeight: 600
                      }}
                    >
                      {moment(item.createdAt).format("DD MMMM, YYYY, hh:mm a")}
                    </span>
                  </div>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a4a4a",
                      marginTop: "10px"
                    }}
                  >
                    {item.comment}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h5> No Reviews Yet</h5>
        )}
      </div>
    </div>
  );
};

export default Reviews;
