import { useState } from "react";
import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import { Pagination } from "../../../../components/Pagination";
import clsx from "clsx";
import { DeleteOrgModal } from "../deleteOrgModal/DeleteOrgModal";

const headers = [
  { title: 'Organization Name', width: '240px' },
  { title: 'Owner Name', width: '180px' },
  { title: 'Members', width: '150px' },
  { title: 'Description', width: '200px' },
  { title: 'Action', width: '120px', className: 'text-end' },
]

export const OrgTable = ({ organizations, isLoading, haveAccess, currentUser, fetchOrganizations, page, setPage, pageSize, setPageSize, total }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map(column => (
                <th
                  key={column.title}
                  className={column.className}
                  style={{ minWidth: column.width }}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {organizations && organizations.length > 0 ? (
              organizations.map(row => (
                <tr key={row.role_id}>
                  <td style={{ minWidth: headers[1].width }}>
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3" style={{ width: "50px", height: "50px" }}>
                          <div
                            className={clsx(
                              "symbol-label fs-3",
                              `bg-light-primary`,
                              `text-primary`
                            )}
                          >
                            {row?.org_name?.slice(0, 1)}
                          </div>
                      </div>
                      <div className="d-flex flex-column mx-2">
                        <span className="text-gray-800 mb-1">
                          {row.org_name}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td style={{ minWidth: headers[0].width }}>{row.owner_name}</td>
                  <td style={{ minWidth: headers[2].width }}>
                    <span className="badge badge-light-primary fw-bolder fs-5">{row.members}</span>
                  </td>
                  <td style={{ minWidth: headers[3].width, display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                    {row.description || '-'}
                  </td>
                  <td style={{ minWidth: headers[4].width }} className="text-end">
                    <button
                      className="btn btn-danger btn-sm"
                      disabled={(haveAccess(currentUser, 'org_delete') ? false : true) || isLoading}
                      style={{
                        backgroundColor: '#ed2626',
                      }}
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setIdToDelete(row.org_id);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
      />
      {isLoading && <UsersListLoading />}
      {openDeleteModal && idToDelete && (
        <DeleteOrgModal setOpenDeleteModal={setOpenDeleteModal} idToDelete={idToDelete} setIdToDelete={setIdToDelete} fetchOrganizations={fetchOrganizations} />
      )}
    </KTCardBody>
  );
};
