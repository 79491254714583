import { useState } from "react";
import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import dayjs from "dayjs";
import { Pagination } from "../../../../components/Pagination";
import { Link } from "react-router-dom";
import { VideoApprovalModal } from "../VideoApprovalModal/VideoApprovalModal";
import axios from "axios";
import { toast } from "react-toastify";

const headers = [
  { title: 'Listing Name', className: 'w-200' },
  { title: 'Date', className: 'w-200' },
  { title: 'Approval For', className: 'w-200' },
  { title: 'New Changes', className: 'w-200' },
  { title: 'Action', className: 'w-200' },
]

export const VideoTable = ({
  videos,
  isLoading,
  fetchVideoApprovals
}) => {
const [openVideoApprovalModal, setOpenVideoApprovalModal] = useState(false);
const [video, setVideo] = useState(null)

const PendingApproval = (type, vid) => {
  if (type) {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/listing/approval/${vid.id}?listing_id=${vid.listing_id}&is_approved=true`
      )
      .then((res) => {
        toast("Approval Accepted", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
        fetchVideoApprovals();
      })
      .catch((err) => {
        toast("Error Pending Approval", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      });
  } else {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/listing/approval/${vid.id}?listing_id=${vid.listing_id}&is_approved=false`
      )
      .then((res) => {
        toast("Approval Rejected", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
        fetchVideoApprovals();
      })
      .catch((err) => {
        toast("Error Pending Approval", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      });
  }
};

  return (
    <KTCardBody className='py-4'>
      {/* <h6 style={{ color: '#7e8299', textAlign: 'right' }}>Total: {total}</h6> */}
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map(column => (
                <th
                  key={column.title}
                  className={column.className}
                  style={{textAlign: column.title === 'Action' ? 'center' : 'left'}}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {videos.length > 0 ? (
              videos.map((row, i) => (
                <tr key={row.id}>
                  <td className='w-200' style={{minWidth:'180px'}}><Link to={`/listings/${row.listing_id}`} style={{color:'inherit'}}>{row.listing_name || '-'}</Link></td>
                  <td className='w-200' style={{minWidth:'180px'}}>{dayjs(row.created_at).format('MMMM DD YYYY')}</td>
                  <td className='w-200' style={{minWidth:'180px'}}>{row.field_id == 1 ? 'Name' : row.field_id == 2 ? "Description" : row.field_id == 5 ? 'Important Information' : 'Video' || '-'}</td>
                  <td className='w-200' style={{minWidth:'180px', maxWidth:'250px'}}>
                    {row.field_id == 2 ? 
                    <p dangerouslySetInnerHTML={{__html: row.value}} />
                    : row.field_id == 24 ?
                    <a href={row.value} target="_blank"><span style={{padding:'10px', fontWeight: '700', background:'#ffa459', borderRadius: '12px', color:'#fff'}}>View Video</span></a>
                    : row.value
                    }
                  </td>
                  <td className='w-200' style={{textAlign: 'center', display:'flex', gap:'5px', justifyContent:'flex-end'}}
                  ><span style={{padding:'10px', fontWeight: '700', background:'#2ecc71', borderRadius: '12px', color:'#fff', cursor:'pointer'}}
                  onClick={() => {
                   PendingApproval(true, row)
                  }}
                  >Approve</span>
                  <span style={{padding:'10px', fontWeight: '700', background:'#ed2626', borderRadius: '12px', color:'#fff', cursor:'pointer'}}
                   onClick={() => {
                    PendingApproval(false, row)
                   }}
                  >Reject</span>
                  </td>           
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isLoading && <UsersListLoading />}
      {
      openVideoApprovalModal &&
      <VideoApprovalModal
        openVideoApprovalModal={openVideoApprovalModal}
        setOpenVideoApprovalModal={setOpenVideoApprovalModal}
        video={video}
        setVideo={setVideo}
        fetchVideoApprovals={fetchVideoApprovals}
        />
      }
    </KTCardBody>
  );
};
