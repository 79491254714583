import React from "react";
import { useState, useEffect } from "react";
import { KTCard, KTCardBody, KTIcon, useDebounce } from "../../../_metronic/helpers";
import axios from "axios";
import "./style.css";
import { PromoListHeader } from "./header/PromoListHeader";
import { toast } from "react-toastify";
import { AddNewPromoModal } from "./promo-new-modal/AddNewPromoModal";
import moment from "moment";
import clsx from "clsx";
import { UsagesModal } from "./usages-modal/UsagesModal";
import { UserEditModal as PromoModal } from "./PromoModal/UserEditModal"
import { ListingModal } from "./ListingModal/ListingModal"
import { Link } from "react-router-dom";
import { useAuth } from "../auth";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

const PromoCode = () => {
  const { currentUser, haveAccess } = useAuth();
  const [promos, setPromos] = useState([]);
  const [openPromoModal, setOpenPromoModal] = useState(false);
  const [openUsagesModal, setOpenUsagesModal] = useState(false);
  const [openPromoCycleModal, setOpenPromoCycleModal] = useState(false);
  const [openListingModal, setOpenListingModal] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [tab, setTab] = useState("active");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [total, setTotal] = useState(1);
  const [usages, setUsages] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [promoCycleId, setPromoCycleId] = useState(null);
  const [searchType, setSearchType] = useState('');
  const [searchPromoCode, setSearchPromoCode] = useState("");
  const [filter, setFilter] = useState({
    promoType: "",
    discountType: "",
  })
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [exportPromosData, setExportPromosData] = useState([]);

  const mapPromoData = (promoCode) => {  
    const {
      createdAt,
      code,
      promo_type,
      discount_type,
      discount,
      requester_name,
      start_date,
      expiry_date,
      is_active,
      notes,
      ends_on,
      is_cyclic,
      total_usage,
      limit_overall,
      limit_user,
      duration,
      is_ffa,
    } = promoCode;
  
    const formattedDate = (date, isUTC) => {
      if(isUTC){
        return moment.utc(date).format("YYYY-MM-DD");
      }else{
        return moment(date).format("YYYY-MM-DD");
      }
    }
  
    const promoTypeMap = {
      1: "Hourly",
      2: "Daily",
      3: "Weekly",
      4: "Monthly",
    };
  
    const discountTypeMap = {
      1: "Flat",
      2: "Percentage",
    };
  
    const discountValue = () => {
      if (discount_type === 1) return `$${(discount / 100).toFixed(2)}`;
      if (discount_type === 2) return `${discount}%`;
      if (discount_type === 3) {
        if (is_ffa) return "Free For All";
        return `${promoCode.duration} ${promoCode?.promo_type === 1 ? "Hours" : "Days"}`;
      }
      return "";
    };
  
    const discountTypeText = () => {
      if (discount_type === 3) {
        if (is_ffa) return `${duration} ${duration === 1 ? "Hour" : "Day"} Off`;
        return `X Amount of ${+promo_type === 1 ? "Hours" : "Days"} OFF`;
      }
      return discountTypeMap[discount_type];
    };
  
    return {
      createdAt: formattedDate(createdAt),
      promo_code: code,
      promo_type: promoTypeMap[promo_type] || "For All",
      discount_type: discountTypeText(),
      discount_value: discountValue(),
      requester_name: requester_name || "N/A",
      start_date: formattedDate(start_date, true),
      expiry_date: formattedDate(expiry_date, true),
      status: tab === "active" ? "Active" : "Inactive",
      notes: notes || "N/A",
      end_date: ends_on || formattedDate(expiry_date, true),
      is_cyclic: is_cyclic ? "Yes" : "No",
      total_usages: total_usage || 0,
      limit_overall: limit_overall || 'N/A',
      limit_user: limit_user || 'N/A',
    };
  };

  const updatePage = (type) => {
    if (type === "dec" && page > 1 && page !== 1) {
      setPage(page - 1);
    } else if (type === "inc" && page >= 1) {
      if (page !== Math.ceil(total / pageSize)) {
        setPage(page + 1);
      }
    } else {
      setPage(type);
    }
  };

  useEffect(() => {
    setPage(1);
    setPageSize(15);
  }, [tab]);


  useEffect(() => {
    setSearchPromoCode("");
    setEndDate('');
    setStartDate('');
  }, [searchType])

  const fetchPromoCode = () => {
    if (tab === "active") {
      axios.get(`${process.env.REACT_APP_API_URL}/promo?page=${page}&page_size=${pageSize}${searchType && debouncedSearchTerm && `&${searchType == 1 ? "promo_code" : searchType == 5 ? "listing_name" : searchType == 4 ? "requester_name" : searchType == 2 ? "promo_type" : searchType == 3 && "discount_type"}=${debouncedSearchTerm}`}
      ${startDate && endDate && `&start_date=${dayjs(startDate).format("YYYY-MM-DD")}&end_date=${dayjs(endDate).format("YYYY-MM-DD")}`}
      `)
        .then((res) => {
          setPromos(res.data.promo_codes);
          setTotal(res.data.total);
          setExportPromosData(res.data.promo_codes.map((item) => {
            return {
             ...mapPromoData(item)
            }
          }));
        })
        .catch((err) => {
          if (err && err.response && err.response?.data && err.response?.data?.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Technical Error, Please Try Again");
          }
        });
    } else {
      axios.get(`${process.env.REACT_APP_API_URL}/promo/inactive?page=${page}&page_size=${pageSize}${searchType && debouncedSearchTerm && `&${searchType == 1 ? "promo_code" : searchType == 5 ? "listing_name" : searchType == 4 ? "requester_name" : searchType == 2 ? "promo_type" : searchType == 3 && "discount_type"}=${debouncedSearchTerm}`}
      ${startDate && endDate && `&start_date=${dayjs(startDate).format("YYYY-MM-DD")}&end_date=${dayjs(endDate).format("YYYY-MM-DD")}`}`)
        .then((res) => {
          setPromos(res.data.promo_codes);
          setTotal(res.data.total);
          setExportPromosData(res.data.promo_codes.map((item) => {
            return {
             ...mapPromoData(item)
            }
          }));
        })
        .catch((err) => {
          if (err && err.response && err.response?.data && err.response?.data?.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Technical Error, Please Try Again");
          }
        });
    }
  };

  const debouncedSearchTerm = useDebounce(searchPromoCode?.trim(), 250);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchPromoCode !== undefined) {
      fetchPromoCode();
    }
    setSelectedPromo(null);
  }, [tab, page, pageSize, searchType, debouncedSearchTerm, filter, startDate, endDate]);

  const getBookings = (code, usages) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/booking?page=1&page_size=${usages}&promo_code=${code}`)
      .then(res => {
        setUsages(res.data.bookings);
        setPromoCode(code);
        setOpenUsagesModal(true);
      })
      .catch(err => {
        toast.error(err?.response?.data?.message || "Error");
      });
  };


  const copyPromoCode = (code, type) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_API_CUSTOMER_URL}?promo=${code}${(+type === 1 || +type === 2 || +type === 3 || +type === 4) ? `&type=${type}` : ''}`);
    toast.success("Promo Code Link Copied");
  };


  const handleExport = async () => {

    if (exportPromosData?.length === 0) {
      toast.error("No data to export");
      return
    }

    const customHeaders = {
      createdAt: "Created At",
      promo_code: "Promo Code",
      promo_type: "Promo Type",
      discount_type: "Discount Type",
      discount_value: "Discount",
      is_cyclic: "Cyclic",
      start_date: "Start Date",
      expiry_date: "Expiry Date",
      end_date: "End Date",
      total_usages: "Total Usages",
      limit_user: "Limit Per User",
      limit_overall: "Total Uses Allowed",
      status: "Status",
      requester_name: "Requester Name",
      notes: "Notes",
    };

    const customColumnWidths = {
      createdAt: 20,
      promo_code: 25,
      promo_type: 20,
      discount_type: 25,
      discount_value: 20,
      is_cyclic: 20,
      start_date: 20,
      expiry_date: 20,
      end_date: 20,
      total_usages: 20,
      limit_user: 20,
      limit_overall: 20,
      status: 20,
      requester_name: 20,
      notes: 35,
    };

    const formattedData = exportPromosData?.map((row) => {
      const newRow = {};
      Object.keys(customHeaders).forEach((key) => {
        if (key in row) {
          newRow[key] = row[key];
        }
      });
      return newRow;
    });

    const ws = XLSX.utils.json_to_sheet(formattedData, {
      header: Object.keys(customHeaders),
    });

    ws["!cols"] = Object.keys(customHeaders).map((key) => ({
      width: customColumnWidths[key] || 15
    }));

    Object.keys(customHeaders).forEach((key, index) => {
      const headerCell = ws[XLSX.utils.encode_cell({ r: 0, c: index })];
      if (headerCell) {
        headerCell.v = customHeaders[key];
      }
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "promo_codes.xlsx");
  };


  return (
    <KTCard>
      <PromoListHeader setOpenPromoModal={setOpenPromoModal} setTab={setTab} tab={tab} currentUser={currentUser} haveAccess={haveAccess}
        setSearchType={setSearchType} searchType={searchType} setSearchPromoCode={setSearchPromoCode} searchPromoCode={searchPromoCode}
        setFilter={setFilter} filter={filter} setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate}
        handleExport={handleExport}
      />
      {(haveAccess(currentUser, 'promo_list')) ?
        <KTCardBody>
          <div className="row g-4">
            {promos && promos.length > 0 ? (
              <div
                className="mb-10 mt-5 rest-stop-container"
              >
                {promos.map((item, index) => (
                  <div
                    className="form-check form-check-custom form-check-solid"
                    key={index}
                    style={{
                      background: tab === "active" ? "#e3feee9e" : "#f9f9f9",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      borderRadius: "5px",
                      flexWrap: "wrap"
                    }}
                  >
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Promo Code {item.discount_type !== 3 && tab === 'active' && <span className="pointer"
                        onClick={() => copyPromoCode(window.btoa(item.code), item.promo_type)}
                      >&#9875;</span>
                      }</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>{item.code}</h5>
                    </div>
                    <div className="fv-row w-50" style={{ textAlign: "right" }}>
                      <label className="fw-bold fs-6 mb-2">Promo Code Type</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>
                        {+item.promo_type === 1 ? "Hourly"
                          : +item.promo_type === 2 ? "Daily"
                            : +item.promo_type === 3 ? "Weekly"
                              : +item.promo_type === 4 ? "Monthly"
                                : "For All"
                        }
                      </h5>
                    </div>
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Discount Type</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>
                        {+item.discount_type === 1 ? "Flat"
                          : +item.discount_type === 2 ? "Percentage"
                            : (+item.discount_type === 3 && item?.is_ffa) ? `${item.duration} ${item.duration === 1 ? +item.promo_type === 1 ? "Hour" : "Day" : +item.promo_type === 1 ? "Hours" : "Days"} Off`
                              : (+item.discount_type === 3 && !item?.is_ffa) ? `X Amount of ${+item.promo_type === 1 ? "Hours" : "Days"} OFF`
                                : ""
                        }
                      </h5>
                    </div>

                    <div className="fv-row w-50" style={{ textAlign: "right" }}>
                      <label className="fw-bold fs-6 mb-2">Discount Value</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>
                        {+item.discount_type === 1 ? `$${(item?.discount / 100)?.toFixed(2)}`
                          : +item.discount_type === 2 ? `${item.discount}%`
                            : +item.discount_type === 3 ? item.is_ffa ? "Free For All"
                              : item.duration : ""
                        }
                      </h5>
                    </div>
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Start Date</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>
                        {item.start_date ? moment(item.start_date).format("YYYY-MM-DD") : moment(item.createdAt).format("YYYY-MM-DD")}
                      </h5>
                    </div>
                    <div className="fv-row w-50" style={{ textAlign: "right" }}>
                      <label className="fw-bold fs-6 mb-2">Expiration Date</label>
                      <h5 style={{
                        color: "#ffa459",
                        fontWeight: 700
                      }}>{moment(item.expiry_date).utc().format("YYYY-MM-DD")}</h5>
                    </div>
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Total Uses Allowed</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>{item.limit_overall || "-"}</h5>
                    </div>
                    <div className="fv-row w-50" style={{ textAlign: "right" }}>
                      <label className="fw-bold fs-6 mb-2">Limit Per User</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>
                        {item.limit_user || "-"}
                      </h5>
                    </div>
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Total Usage</label>
                      <div
                        style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                        <h5 style={{ color: "#ffa459", fontWeight: 700, margin: "0" }}>{item.total_usage || "0"}</h5>
                        {!!item.total_usage && (
                          <button
                            style={{
                              backgroundColor: "rgb(255, 164, 89)",
                              border: "none",
                              color: "white",
                              borderRadius: "2px",
                              padding: '4px'
                            }}
                            onClick={() => getBookings(item.code, item.total_usage || 1)}
                          >
                            Show
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="fv-row w-50" style={{ textAlign: "right" }}>
                      <label className="fw-bold fs-6 mb-2">Promo End Date</label>
                      <h5 style={{
                        color: "#ffa459",
                        fontWeight: 700
                      }}>{item.ends_on ? item.ends_on : moment(item.expiry_date).utc().format("YYYY-MM-DD")}</h5>
                    </div>
                    {item.is_ffa && (
                      <>
                        <div className="fv-row w-50">
                          <label className="fw-bold fs-6 mb-2">Listing Name</label>
                          {item.listings?.length < 2 ?
                            <h5
                              style={{
                                color: "#ffa459",
                                fontWeight: 700,
                                maxWidth: "150px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden"
                              }}
                            >
                              <Link
                                to={`/listings/${item?.listings[0]?.listing_id}`}
                                style={{ color: "#ffa459", fontWeight: 700 }}
                              >
                                {item?.listings[0]?.listing_name || "-"}
                              </Link>
                            </h5>
                            :
                            <h5>
                              <button
                                style={{
                                  backgroundColor: "rgb(255, 164, 89)",
                                  border: "none",
                                  color: "white",
                                  borderRadius: "2px",
                                  padding: '4px'
                                }}
                                onClick={() => {
                                  setSelectedPromo(item)
                                  setOpenListingModal(true)
                                }}
                              >
                                Show All Listings
                              </button>
                            </h5>
                          }
                        </div>
                      </>
                    )}
                    {/* {!item.is_ffa && (
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Starting Date</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>
                        {item.start_date ? moment(item.start_date).format("YYYY-MM-DD") : moment(item.createdAt).format("YYYY-MM-DD")}
                      </h5>
                    </div>
                  )} */}
                    {(item.is_ffa) && (
                      <>
                        <div className="fv-row w-50" style={{ textAlign: "right" }}>
                          <label className="fw-bold fs-6 mb-2">Is Cyclic</label>
                          <h5 style={{ color: "#ffa459", fontWeight: 700 }}>{item.is_cyclic ? "Yes" : "No"}
                            {item.is_cyclic && (haveAccess(currentUser, 'promo_cycles')) && (
                              <button
                                style={{
                                  backgroundColor: "rgb(255, 164, 89)",
                                  border: "none",
                                  color: "white",
                                  borderRadius: "2px",
                                  marginLeft: "6px",
                                  padding: '4px'
                                }}
                                onClick={() => {
                                  setPromoCycleId(item.cycle_id);
                                  setOpenPromoCycleModal(true);
                                }}
                              >
                                Show All
                              </button>
                            )}
                          </h5>
                        </div>
                        {/* <div className="fv-row w-50" style={{ textAlign: "right" }}>
                        <label className="fw-bold fs-6 mb-2">Starting Date</label>
                        <h5 style={{ color: "#ffa459", fontWeight: 700 }}>
                          {item.start_date ? moment(item.start_date).format("YYYY-MM-DD") : moment(item.createdAt).format("YYYY-MM-DD")}
                        </h5>
                      </div> */}
                      </>
                    )}
                    {/* {item.is_ffa && !item.is_cyclic && (
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Starting Date</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>
                        {item.start_date ? moment(item.start_date).format("YYYY-MM-DD") : moment(item.createdAt).format("YYYY-MM-DD")}
                      </h5>
                    </div>
                  )} */}
                    <div className="fv-row w-50">
                      <label className="fw-bold fs-6 mb-2">Created At</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>{moment(item.createdAt).format("YYYY-MM-DD")}</h5>
                    </div>
                    <div className="fv-row w-50" style={{ textAlign: "right" }}>
                      <label className="fw-bold fs-6 mb-2">Requested By</label>
                      <h5 style={{
                        color: "#ffa459",
                        fontWeight: 700
                      }}>{item.requester_name ? item.requester_name : '-'}</h5>
                    </div>
                    <div className="fv-row w-100">
                      <label className="fw-bold fs-6 mb-2">Note</label>
                      <h5 style={{ color: "#ffa459", fontWeight: 700 }}>{item.notes ? item.notes : '-'}</h5>
                    </div>
                    <div className="fv-row w-50">
                    </div>
                    {(haveAccess(currentUser, 'promo_edit')) && <button
                      className="listing-remove"
                      onClick={() => {
                        setSelectedPromo(item);
                        setOpenPromoModal(true);
                      }}
                    >
                      <KTIcon iconName="pencil" />
                    </button>
                    }
                  </div>
                ))}
              </div>
            ) : (
              <div className="d-flex text-center w-100 align-content-center justify-content-center">
                No Promo Code Found
              </div>
            )}
          </div>
          <div className="row mt-10">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <select
                  className="form-select form-select-solid fw-bolder"
                  data-kt-select2="true"
                  data-placeholder="Select Status"
                  data-allow-clear="true"
                  data-kt-user-table-filter="two-step"
                  data-hide-search="true"
                  onChange={(e) => setPageSize(e.target.value)}
                  value={pageSize}
                >
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  <option value="60">60</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-lg-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination">
                  {page > 1 && (
                    <li className={clsx("page-item", "previous")}>
                      <button
                        className={clsx("page-link", "next", "page-text")}
                        onClick={() => updatePage("dec")}
                        style={{ cursor: "pointer" }}
                        disabled={page === 1}
                      >
                        Previous Page
                      </button>
                    </li>
                  )}
                  <div style={{ display: "flex", gap: "5px", fontWeight: 700, fontSize: "14px" }}>
                    <span style={{ color: "#ffa459" }}>{page}</span>/ {Math.ceil(total / pageSize)}
                  </div>
                  <li className={clsx("page-item", "previous", page === Math.ceil(total / pageSize) && "disabled")}>
                    <button
                      className={clsx("page-link")}
                      onClick={() => updatePage("inc")}
                      style={{
                        cursor: page !== Math.ceil(total / pageSize) ? "pointer" : "default",
                        background: page !== Math.ceil(total / pageSize) ? "transparent" : "#e6e6e6",
                        color: page !== Math.ceil(total / pageSize) ? "#5e6278" : "#b2b2b2",
                        marginLeft: page === Math.ceil(total / pageSize) && "10px"
                      }}
                    >
                      Next Page
                    </button>
                  </li>
                  {Math.ceil(total / pageSize) > 5 && <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: '10px' }}>
                    <h6 style={{ marginBottom: '0' }}>Go To Page: </h6>
                    <select
                      style={{ width: "max-content" }}
                      className="form-select form-select-solid fw-bolder"
                      data-kt-select2="true"
                      data-placeholder="Select Status"
                      data-allow-clear="true"
                      data-kt-user-table-filter="two-step"
                      data-hide-search="true"
                      onChange={(e) => updatePage(+e.target.value)}
                      value={page}
                    >
                      {
                        [...Array(Math.ceil(total / pageSize))].map((_, i) => {
                          return (
                            <option value={i + 1}>{i + 1}</option>
                          )
                        }
                        )}
                    </select>
                  </div>
                  }
                </ul>
              </div>
            </div>
          </div>
        </KTCardBody>
        : <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
          You do not have permission to view
        </div>
      }
      {openPromoModal && (
        <AddNewPromoModal
          openPromoModal={openPromoModal}
          setOpenPromoModal={setOpenPromoModal}
          fetchPromoCode={fetchPromoCode}
          selectedPromo={selectedPromo}
          setSelectedPromo={setSelectedPromo}
          tab={tab}
        />
      )}
      {openPromoCycleModal && (
        <PromoModal
          setOpenPromoCycleModal={setOpenPromoCycleModal}
          promoCycleId={promoCycleId}
          setPromoCycleId={setPromoCycleId}
          getBookings={getBookings}
        />
      )}
      {openListingModal && (
        <ListingModal
          setOpenListingModal={setOpenListingModal}
          selectedPromo={selectedPromo}
          setSelectedPromo={setSelectedPromo}
        />
      )}
      {openUsagesModal && (
        <UsagesModal
          promo={promoCode}
          usages={usages}
          onClose={() => {
            setPromoCode("");
            setUsages([]);
            setOpenUsagesModal(false);
          }}
        />
      )}
    </KTCard>
  );
};

export default PromoCode;
