import { useEffect, useState } from "react";
import { KTCard} from "../../../_metronic/helpers";
import "./style.css";
import axios from "axios";
import { toast } from "react-toastify";
import { OrgHeader } from "./header/OrgHeader";
import { OrgTable } from "./table/OrgTable";
import { useAuth } from "../auth";
import { useDebounce } from "../../../_metronic/helpers";


const Index = () => {
    const { currentUser, haveAccess } = useAuth();
    const [organizations, setOrganizations] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setLoading] = useState(false);


    const getOrganizations = () => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_V3_URL}/org?page=${page}&page_size=${pageSize}&name=${search}`)
            .then((res) => {
                const response = res.data;
                setOrganizations(response?.orgs);
                setTotal(response?.total);
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                toast.error(err?.response?.data?.message || 'Could not fetch organizations');
            })
    }


  const debouncedSearchTerm = useDebounce(search, 250);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      getOrganizations();
    }
  }, [debouncedSearchTerm, page, pageSize]);


    return (
        <KTCard>
            <OrgHeader search={search} setSearch={setSearch} />
            {(haveAccess(currentUser, 'org_list')) ? 
                <OrgTable
                organizations={organizations}
                isLoading={isLoading}
                haveAccess={haveAccess}
                currentUser={currentUser}
                fetchOrganizations={getOrganizations}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={total}
            />
                :
                <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
                    You do not have permission to view
                </div>
            }
        </KTCard>
    );
};

export default Index;
