import { useEffect, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";

const AddToBlacklistModalForm = ({ setOpenModal, fetchBlacklist, type }) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const cancel = () => {
    setEmail("");
    setPhone("");
    setOpenModal(false);
  };

  useEffect(() => {
    setEmail("");
  }, []);

  const addToBlacklist = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_V2_URL}/blacklist`, {
        type: +type,
        ...(type === '1' && { email }),
        ...(type === '2' && { phone })
      })
      .then((res) => {
        toast.success(`New ${type === '1' ? 'Email' : 'Phone'} have been Added to the Blacklist`);
        cancel();
        fetchBlacklist();
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  return (
    <>
      <form className="form" id="kt_modal_add_user_form" autoComplete="off" onSubmit={addToBlacklist} method="post" action="">
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          {type === '1' && (
            <div className="fv-row">
              <label className="required fw-bold fs-6 mb-2">Email</label>
              <input
                placeholder="Email"
                className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                type="email"
                name="email"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly
                onFocus={(e) => {
                  e.target.readOnly = false;
                }}
                onTouchStart={(e) => {
                  e.target.readOnly = false;
                }}
                onBlur={() => {
                  if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                    setEmail(email);
                  } else {
                    setEmail("");
                    toast.error("Please Enter Valid Email");
                  }
                }}
              />
            </div>
          )}
          {type === '2' && (
            <div className="fv-row">
              <label className="required fw-bold fs-6 mb-2">Phone</label>
              <input
                placeholder="Phone"
                className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                type="text"
                name="phone"
                autoComplete="off"
                value={phone}
                onChange={(e) => {
                  const re = /^[0-9]*$/
                  if (!re.test(e.target.value)) {
                    return
                  } else {
                    setPhone(e.target.value);
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Discard
          </button>
          <button
            type="submit"
            className="btn"
            data-kt-users-modal-action="submit"
            style={{ background: "#ffa459", color: "#fff" }}
            disabled={type === '1' ? !email : !phone}
          >
            <span className="indicator-label">Submit</span>
          </button>
        </div>
      </form>
    </>
  );
};

export { AddToBlacklistModalForm };
